body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* header */

.header {
  width: 100%;
}

.top-bar {
  background-color: #1b1b1b !important;
  padding: 8px !important;
}

.top-bar-content {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
}

.language-button {
  color: white !important;
}

.donate-text {
  color: white !important;
  margin-left: 16px !important;
}

.contact-info {
  display: flex !important;
  align-items: center !important;
  color: white !important;
  justify-content: flex-end !important;
}

.contact-icon {
  font-size: 1rem !important;
  margin-right: 8px !important;
}

.social-icons {
  display: flex !important;
}

.social-icon-button {
  color: white !important;
  border-radius: 50% !important;
  padding: 4px !important;
  margin: 0 6px !important;
  font-size: 15px !important;
}

.main-header {
  padding: 10px !important;
}

.logo {
  max-width: 150px !important;
}

.nav-menu {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.nav-button {
  margin: 0 8px !important;
  color: black !important;
}

.donate-button {
  background-color: #d40032  !important;
  color: white !important;
  margin-left: 16px !important;
}

@media only screen and (max-width: 768px) {
  .donate-button {
    font-size: 8px !important;
    padding: 4px 8px !important;
    min-width: 70px !important;
  }
}

/* slider section */

.slider-container {
  height: 50vh !important;
  @media screen and (min-width: 600px) {
      height: 80vh !important;
  }
  position: relative !important;
  margin: 0 !important; /* Remove any margin */
}

.slide-background {
  height: 100% !important; /* Ensure it takes full height */
  width: 100% !important;
  background-size: cover !important;
  background-position: center !important;
  position: relative !important;
}


.swiper-container {
  height: 100% !important;
}

.swiper-slide {
  height: 100% !important;
}



.slide-overlay {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0.0, 0.0, 0.0, 0.0) !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px !important;
  text-align: center !important;
}

@media screen and (min-width: 600px) {
  .slide-overlay {
      padding: 16px !important;
  }
}

.slide-logo {
 
  margin-bottom: 20px !important;
  max-width: 80% !important;
  max-height: 80% !important;
}
.slide-title {
  font-family: 'Montserrat', sans-serif; /* Corrected syntax */
  margin-bottom: 4px !important; /* Reduced gap */
  font-weight: 550 !important;
  color: rgb(105, 18, 18) !important;
  font-size: 2rem !important; /* Default size */
}


/* Default size for smaller screens (xs) */
.slide-title {
  font-size: 1rem !important;
}

/* Size for larger screens (lg) */
@media screen and (min-width: 960px) {
  .slide-title {
    font-size: 2rem !important; /* Adjust size as needed */
  }
}

@media screen and (min-width: 600px) {
  .slide-title {
      font-size: 1rem !important;
  }
}

@media screen and (min-width: 960px) {
  .slide-title {
      font-size: 3.5rem !important;
  }
}

.subtitle {
  margin-top: -80px; /* Adjusted negative margin to reduce gap */
  font-weight: normal; 
  color: white; 
}

/* Default size for smaller screens (xs) */
.subtitle {
  font-size: 1.5rem; /* Adjust size as needed */
}

/* Size for larger screens (lg) */
@media screen and (min-width: 960px) {
  .subtitle {
    font-size: 2.5rem; /* Adjust size as needed */
  }
}

.slide-title-line {
  display: block !important;
}


.slide-buttons {
  display: flex !important;
  flex-direction: row !important; /* Set to row for xs */
  gap: 8px !important; /* Adjust gap for buttons */
  justify-content: center !important;
  width: 100% !important; /* Full width */
  margin-top: 8px !important;
  margin-right: 18px;
}

@media screen and (min-width: 600px) {
  .slide-buttons {
      margin-top: 16px !important;
      gap: 16px !important;
      width: 40%; /* Adjusted width for larger screens */
  }
}

.slide-button {
  width: 40%; /* Set a specific width for buttons on xs */
 
}



.slide-button {
  font-size: 0.7rem !important;
  padding: 6px 10px !important;
  min-width: 100px !important;
  transition: all 0.3s ease !important;
  color: white !important;
  background-color: #d40032  !important;
}

@media screen and (min-width: 600px) {
  .slide-button {
      font-size: 0.9rem !important;
      padding: 10px 16px !important;
      min-width: 150px !important;
  }
}

.slide-button:hover {
  background-color: white !important;
  color: #d40032  !important;
}


/* About */

.about-container {
  padding: 30px 16px;
  background-color: #ffffff !important;
  margin-bottom: -40px;
}

@media (min-width: 600px) {
  .about-container {
    padding: 60px 16px !important;
  }
}

@media (min-width: 960px) {
  .about-container {
    padding: 40px 14px !important;
  }
}

.about-inner-container {
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.about-title {
  font-family: 'Playfair Display, serif';
  font-size: 24px !important;
  line-height: 1.2 !important;
  color: #302c51 !important;
  text-align: left !important;
  margin-bottom: 16px !important;
  font-weight: 700 !important;
}

@media (min-width: 600px) {
  .about-title {
    font-size: 36px !important;
  }
}

@media (min-width: 960px) {
  .about-title {
    font-size: 36px !important;
  }
}

.about-quote {
  font-size: 16px !important;
  color: #7F8C8D !important;
  text-align: left !important;
  margin-bottom: 32px !important;
  font-weight: 600 !important;
  font-style: italic !important;
}

@media (min-width: 600px) {
  .about-quote {
    font-size: 18px !important;
  }
}

.about-description {
  color: #424242!important;
  text-align: left !important;
  margin-bottom: 32px !important;
  font-size: 16px !important;
  line-height: 1.6 !important;
}

@media (min-width: 600px) {
  .about-description {
    font-size: 16px !important;
  }
}

.mission-vision-container {
  margin-bottom: 32px !important;
}

.mission-vision-button {
  border-radius: 10px;
  padding: 10px 16px !important;
  text-transform: none !important;
  font-weight: bold !important;
  color: #ffffff !important;
  background-color: #d40032  !important;
}



.mission-vision-text {
  margin-top: 8px !important;
  color: #7F8C8D !important;
  text-align: left !important;
  font-size: 12px !important;
}

@media (min-width: 600px) {
  .mission-vision-text {
    font-size: 14px !important;
  }
}

.more-about-button-container {
  display: flex !important;
  justify-content: center !important;
  margin-top: -8px !important;
  margin-bottom: 10px;
}

.more-about-button {
  border-radius: 5px !important;
  padding: 10px 16px !important;
  text-transform: none !important;
  font-weight: bold !important;
  color: white  !important;
  background-color: #d40032 !important;
  border: 2px solid #d40032  !important;
}

.more-about-button:hover {
  background-color: #d40032  !important;
  color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
}

.image-container {
  position: relative !important;
}

.image-wrapper {
  position: relative !important;
  height: 250px !important;
  overflow: hidden !important;
  border-radius: 16px !important;
}

@media (min-width: 600px) {
  .image-wrapper {
    height: 350px !important;
  }
}

@media (min-width: 960px) {
  .image-wrapper {
    height: 600px !important;
  }
}

.main-image {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 70% !important;
  height: 90% !important;
  object-fit: cover !important;
  border-radius: 16px !important;
  border: 5px solid #f8f8f8 !important;
}

.third-image {
  position: absolute !important;
  width: 40% !important;
  height: 60% !important;
  object-fit: cover !important;
  border-radius: 16px !important;
  bottom: 20px !important;
  right: 10px !important;
  z-index: 1 !important;
  border: 5px solid #f8f8f8 !important;
}

.logo-image {
  position: absolute !important;
  top: 10% !important;
  left: 75% !important;
  width: 10vw !important;
  height: 10vw !important;
  max-width: 80px !important;
  max-height: 80px !important;
  padding: 10px !important;
  background-color: #ffffff !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  z-index: 3 !important;
  transform: translate(-50%, -50%) !important;
}


.projects-section {
  position: relative;
  padding: 10px 0 80px;
}

.auto-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.sec-title {
  margin-bottom: 30px;
}

.sec-title h1 {
  font-size: 36px;
  margin-bottom: 10px;
  color: #302c51 !important;
  text-align: center;
}

.sec-title .text {
  font-size: 16px;
  color: #7F8C8D;
  text-align: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.project-block-one {
  flex: 1 1 calc(33.333% - 10px); /* Default to three columns */
  margin-bottom: 30px;
  max-width: calc(33.333% - 10px); /* Ensure proper max width */
}

.project-block-one .inner-box {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-block-one .image {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.project-block-one .image img {
  width: 100%;
  display: block;
}

.project-block-one .gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  transition: background 0.5s ease;
}

.project-block-one .image:hover .gradient-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.project-block-one h3 {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  font-size: 24px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}

.project-block-one h3 a {
  color: #fff;
  text-decoration: none;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .project-block-one {
    flex: 1 1 calc(50% - 10px); /* Two columns on medium screens */
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .project-block-one {
    flex: 1 1 100%; /* One column on small screens */
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .sec-title h1 {
    font-size: 28px;
  }

  .sec-title .text {
    font-size: 14px;
  }

  .project-block-one {
    margin: 0; /* Remove any margin for small mobile screens */
  }
}


cause-block-four {
  position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.col-lg-4,
.col-md-4 {
  flex: 0 0 33.33%; 
  box-sizing: border-box;
}
.cause-block-four .inner-box {
  position: relative;
  border: 1px solid #eaeaea;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  transition: 0.7s ease;
  margin-bottom: 30px;
}





.cause-block-four .inner-box:hover {
  box-shadow: 0px 20px 20px 0px rgba(89, 83, 76, 0.1);
  border-color: #fff;
}


.causes-section-four {
	position: relative;
	padding: 30px 0 30px;
	background: #fff;
  margin-top: 10px;
  margin-bottom: -10px;
}

.cause-block-four {
	position: relative;
}

.cause-block-four .inner-box {
	position: relative;
	border: 1px solid #eaeaea;
	padding-bottom: 40px;
	padding-top: 40px;
	margin-bottom: 20px;
	text-align: center;
	background-color: #fff;
	-webkit-transition: .7s ease;
	-o-transition: .7s ease;
	transition: .7s ease;
}

.cause-block-four .inner-box:hover {
	-webkit-box-shadow: 0px 20px 20px 0px rgba(89, 83, 76, 0.1);
	        box-shadow: 0px 20px 20px 0px rgba(89, 83, 76, 0.1);
	border-color: #fff;
}

.cause-block-four .inner-box:before {
	position: absolute;
	content: '';
	background-image: url(../src/Components/images/resource/shape-2.png);
	background-size: cover;
	background-position: bottom center;
	top: 0;
	left: 0;
	width: 100%;
	height: 170px;
	-webkit-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease;
}



.cause-block-four .image {
	position: relative;
	margin-bottom: 20px;
	display: inline-block;
}

.cause-block-four .image img {
	border-radius: 50%;
}

.cause-block-four .image .overlay {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
}

.cause-block-four .image .overlay:before {
	position: absolute;
	content: '';
	background: #302c51;
	opacity: 0;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	border-radius: 50%;
	-webkit-transition: 1s ease;
	-o-transition: 1s ease;
	transition: 1s ease;
	-webkit-transform: scale(.5);
	    -ms-transform: scale(.5);
	        transform: scale(.5);
}

.cause-block-four .image:hover .overlay:before {
	-webkit-transform: scale(1);
	    -ms-transform: scale(1);
	        transform: scale(1);
	opacity: .93;
}

.cause-block-four .image .overlay .donate-box-btn {
	position: relative;
	font-size: 13px;
	color: #fff;
	text-transform: uppercase;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease;
	-webkit-transition-delay: .3s;
	     -o-transition-delay: .3s;
	        transition-delay: .3s;
}

.cause-block-four .image:hover .overlay .donate-box-btn {
	opacity: 1;
	visibility: visible;
}

.cause-block-four h4 {
	font-size: 20px;
	margin-bottom: 20px;
  text-align: center;
}


.cause-block-four h4 a {
	color: #1e1e1e;
	-webkit-transition: .5s ease;
	-o-transition: .5s ease;
	transition: .5s ease;
}

.cause-block-four h4 a:hover {
	color: var(--theme-color-three);
}

.cause-block-four .text {
	margin-bottom: 30px;
  
}

.cause-block-four .info-box {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	border: 1px solid #eaeaea;
	margin: 0 30px;
	padding: 20px 0;
}

.cause-block-four .info-box a {
	font-size: 13px;
	text-transform: uppercase;
	color: #1e1e1e;
	line-height: 11px;
	position: relative;
	top: 5px;
}

.cause-block-four .info-box a span {
	color: var(--theme-color-three);
	display: block;
}

.cause-block-four .info-box>div {
	position: relative;
	width: 33%;
}

.cause-block-four .info-box>div+div:before {
	position: absolute;
	content: '';
	background: #eaeaea;
	height: 35px;
	width: 1px;
	left: 0px;
	top: 0;
}

.cause-block-four .count-box {
	position: relative;
	font-size: 18px;
	color: var(--theme-color-three);
	padding: 5px 0;
}


.cause-block-four.style-two .inner-box:hover:before {
	background-image: url(../src/Components/images/resource/shape-3.png);
}

.cause-block-four.style-two h4 a:hover {
	color: var(--theme-color);
}

.cause-block-four.style-two .info-box a span {
	color: var(--theme-color);
}

.cause-block-four.style-two .count-box {
	color: var(--theme-color);
}
.text{
 color:   #7F8C8D ;
}
.count-text{
  color: #d40032 ;
}
.affix{
  color: #d40032 ;
}
.raise-donation{
  color: #d40032 ;
}
.goal-donation{
  color: #d40032 ;
}

/* Footer.css */

.main-footer {
  background-color: #1b1b1b;
  padding: 40px 0;
}

.auto-container {
  
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.widget-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-widget {
  padding: 0 15px;
}

.about-widget-three .logo img {
  max-width: 150px;
}

.about-widget-three .text {
  margin: 20px 0;
  font-size: 14px;
  color: #e0e0e0;
  text-align: left;
}

.about-widget-three .copyright-text {
  font-size: 12px;
  color: #999;
  text-align: left;
}

.instagram-widget .widget-title-two {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  text-align: left;
}

.instagram-widget .wrapper-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.instagram-widget .image {
  width: calc(25% - 10px);
}

.instagram-widget .image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
}

.twitter-widget .widget-title-two {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  text-align: left;
}

.twitter-widget .text {
  font-size: 14px;
  color: #e0e0e0;
}

.twitter-widget .link a {
  color: #d40032 ;
  text-decoration: none;
}

.twitter-widget .twitter-portfolio {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.twitter-widget .icon {
  margin-right: 10px;
}

.twitter-widget .twitter-portfolio h4 {
  font-size: 16px;
  margin: 0;
}

.twitter-widget .user {
  color: #d40032 ;
  text-decoration: none;
}

.footer-bottom-three {
  background-color: #333;
  padding: 15px 0;
  color: #fff;
}

.footer-bottom-three .social-icon-four {
  display: flex;
  justify-content: center;
  gap: 15px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-bottom-three .social-icon-four li {
  display: flex;
  align-items: center;
}

.footer-bottom-three .social-icon-four a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #d40032 ;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
}

.scroll-to-top:hover {
  background-color: #d40032 ;
}
/* donate */

.donate-us-container {
  display: flex;
  font-family: Arial, sans-serif;
 
}

.content {
  display: flex;
  flex: 3;
}

.content-image {
  width: 40%;
  object-fit: cover;
}

.donation-form {
  background-color: #d40032 ;
  color: white;
  padding: 2rem;
  flex: 1;
  font-family: 'prata';
  text-align: left;
}

.donation-form h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 100px;
}

.donation-form-para {
  margin-top: 40px;
  font-size: 18px;
  font-family: 'prata';
}

.progress-bar {
  background-color: #ff4d6d;
  height: 10px;
  margin: 1rem 0;
}

.progress {
  background-color: white;
  height: 100%;
}

.progress-text {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-family: 'Rubik', sans-serif;
  margin-top: 50px;
}

.raised {
  font-weight: bold;
}

.goal {
  font-weight: bold;
}

.donation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 30px 0;
}

.donation-buttons button {
  font-size: 18px;
  background: transparent;
  color: #fff;
  border: 1px solid rgba(236, 232, 229, 0.10);
  padding: 20px 43px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.1s ease-out;
  box-shadow: inset 0px 0px 7.2px 0.8px rgba(0, 0, 0, 0.18);
}

.donation-buttons button:hover {
  background-color: #242424;
}

.donation-buttons button:focus {
  outline: none;
}

.custom-donation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.custom-donation input[type="text"] {
  box-shadow: inset 0px 0px 7.2px 0.8px rgba(0, 0, 0, 0.18);
  border-radius: 6px;
  font-size: 18px;
  background: transparent;
  color: #fff;
  border: 1px solid rgba(236, 232, 229, 0.10);
  padding: 10px 20px;
  flex: 1;
  margin: 0; 
}

.custom-donation button.donate-now {
  font-family: inherit;
  font-size: 18px;
  padding: 10px 20px;
  background-color: #fff;
  color: #d40032 ;
  border: none;
  border-radius: 0; 
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-donation button.donate-now:hover {
  background-color: var(--theme-color-two);
  color: #fff; 
  border: 1px solid #fff;
}

.custom-donation input[type="text"]::placeholder {
  color: #ffffff; 
  opacity: 0.7; 
}

.sidebar {
  background-image: url('./Components/images/background/bg-11.jpg');
  color: #999;
  padding: 1rem;
  flex: 1;
  background-size: cover;
  background-position: center; 
  height: 100vh; 
  position: relative;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin-top: 100px;
}

.sidebar li {
  padding: 0.5rem 0;
  cursor: pointer;
  position: relative;
  font-size: 25px;
  margin-top: 20px;
}
@media (max-width: 768px) {
  .sidebar li {
    font-size: 16px; /* Adjust for smaller screens */
  }
}

.sidebar li.active {
  color: white;
  font-weight: bold;
}

.arrow {
  position: absolute;
  left: -5px; 
  width: 0;
  height: 0;
  border-left: 100px solid #d40032 ; 
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: top 0.3s ease;
}

.sidebar li.active ~ .arrow {
  transform: translateY(calc(100% + 10px)); 
}

.payment-donate-section {
  position: relative;
  padding: 110px 0;
  background-color: #222;
  background-size: cover;
}

.payment-donate-section .chicklet-list label {
  font-size: 18px;
  background: transparent;
  color: #fff;
  border-color: rgba(236, 232, 229, 0.10);
  padding: 15px 70px;
  margin-bottom: 0;
}

.payment-donate-section .chicklet-list :checked + label {
  background: #242424;
}

.payment-donate-section .chicklet-list {
  margin: 0 -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.payment-donate-section .chicklet-list li {
  padding: 0 15px 30px;
  margin-bottom: 0;
}

.payment-donate-section .other-amount {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.payment-donate-section form .other-amount > * {
  margin-bottom: 30px;
}

.payment-donate-section .other-amount input {
  position: relative;
  -webkit-box-shadow: inset 0px 0px 7.2px 0.8px rgba(0, 0, 0, 0.18);
  box-shadow: inset 0px 0px 7.2px 0.8px rgba(0, 0, 0, 0.18);
  max-width: 370px;
  border-radius: 6px;
  font-size: 18px;
  background: transparent;
  color: #fff;
  border: 1px solid rgba(236, 232, 229, 0.10);
  padding: 15px 20px;
  padding-left: 80px;
  width: 100%;
}

.payment-donate-section .form-group {
  position: relative;
  margin: 0;
  margin-right: 30px;
}

.payment-donate-section .form-group:before {
  position: absolute;
  content: "$";
  background: var(--theme-color-two);
  left: 0;
  top: 0;
  width: 60px;
  height: 100%;
  text-align: center;
  line-height: 60px;
  color: #1a1a1a;
  font-size: 18px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}


  
/* testimonials */
.testimonial-section {
  text-align: center;
  padding: 50px 0;
  width: 100% !important;
  
  
}

.testimonial-section h1 {
  font-size: 2em;
  margin-bottom: 10px;
  
}

.testimonial-section p {
  margin-bottom: 40px;
}

.testimonial-swiper {
  position: relative;
  padding: 20px 0;
  
}

.testimonial-item {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 8px 2px rgba(89, 83, 76, 0.1);
  border-radius: 10px;
  text-align: center;
}

.testimonial-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.testimonial-item h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.testimonial-item p {
  font-size: 0.9em;
  color: #555;
  margin-bottom: 15px;
}

.testimonial-item h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 5px;
}

.testimonial-item span {
  font-size: 0.85em;
  color: #757575;
}

/* Swiper navigation buttons */


.swiper-container {
  position: relative; 
  width: 100% !important; 
  
}

.swiper-button-next,
.swiper-button-prev {
  color: #000 !important; 
  width: 10px !important; 
  height: 10px !important; 
  top: 50%; 
  transform: translateY(-50%);
  position: absolute; 
  

  border-radius: 50%;
  
}

.swiper-button-next {
  right: 10px !important ; /* Distance from the right */
}

.swiper-button-prev {
  left: 10px !important; /* Distance from the left */
}


/* end testimonials */
  .gallery-section {
    position: relative;
    background: #d40032 ;
  }
  
  .gallery-section .wrapper-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .gallery-section .content-column {
    position: relative;
    padding: 100px 60px 45px;
    width: 25%;
  }
  
  .gallery-section .content-column h5 {
    color: #fff;
  }
  
  .gallery-section .content-column .link-btn {
    text-align: right;
  }
  
  .gallery-section .content-column .link-btn a {
    background: transparent;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
  }
  
  .gallery-section .content-column .link-btn a span {
    margin-right: 10px;
  }
  
  .gallery-section .portfolio-column {
    position: relative;
    width: 75%;
    overflow: hidden;
  }
  
  .gallery-block-one .inner-box {
    position: relative;
  }
  
  .gallery-block-one .image {
    position: relative;
    overflow: hidden;
  }
  
  .gallery-block-one .image img {
    width: 100%;
  }
  
  .gallery-block-one .overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  
  .gallery-block-one .overlay:before {
    position: absolute;
    content: '';
    background: #000;
    left: 0;
    top: 0;
    width: 100%;
    height: 0%;
    opacity: .85;
    transition: .5s ease;
  }
  
  .gallery-block-one .inner-box:hover .overlay:before {
    height: 100%;
  }
  
  .gallery-block-one h4 {
    position: relative;
    font-size: 20px;
    color: #fff;
    margin-bottom: 35px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: .5s ease;
    transition-delay: 1s;
  }
  
  .gallery-block-one .inner-box:hover h4 {
    opacity: 1;
    visibility: visible;
    transform: translateY(2px);
  }
  
  .gallery-block-one h4 a {
    color: #fff;
  }
  
  .gallery-block-one .zoom-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border-radius: 50%;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: .5s ease;
  }
  
  .gallery-block-one .inner-box:hover .zoom-btn {
    opacity: 1;
    visibility: visible;
  }
  
  .gallery-block-one .zoom-btn:before {
    position: absolute;
    content: '';
    background: var(--theme-color-three);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .8s ease;
    transform: scale(.5);
    border-radius: 50%;
    transition-delay: .5s;
  }
  
  .gallery-block-one .inner-box:hover .zoom-btn:before {
    transform: scale(1);
  }
  
  .gallery-block-one .zoom-btn span {
    position: relative;
  }
  
  .gallery-section .owl-nav-style-three .owl-nav {
    z-index: 0;
    height: 0px;
  }
  
  .gallery-section .owl-nav-style-three .owl-nav .owl-prev,
  .gallery-section .owl-nav-style-three .owl-nav .owl-next {
    background: #fff;
    border-color: #fff;
    height: 110px;
    width: 110px;
    line-height: 110px;
    text-align: center;
    font-size: 35px;
  }
  
  .gallery-section .owl-nav-style-three .owl-nav .owl-prev {
    left: -55px;
    text-align: right;
    padding-right: 25px;
  }
  
  .gallery-section .owl-nav-style-three .owl-nav .owl-next {
    right: -55px;
    text-align: left;
    padding-left: 25px;
  }
  
  .gallery-section .owl-nav-style-three .owl-nav .owl-next:hover,
  .gallery-section .owl-nav-style-three .owl-nav .owl-prev:hover {
    color: var(--theme-color-three);
    border-color: #fff;
  }

  /* .contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form select,
.contact-form textarea {
  border-radius: 6px;
  border: 1px solid #eaeaea;
  transition: all 500ms ease;
}

.contact-form input:focus,
.contact-form select:focus,
.contact-form textarea:focus {
  border-color: var(--theme-color);
}

.contact-form textarea {
  resize: none;
  padding: 12px 25px;
}

.contact-form button {
  cursor: pointer;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error {
  border-color: #ff0000 !important;
}

.contact-form label.error {
  color: #ff0000;
  font-weight: 500;
}

.icon-pink {
  color: #d40032  !important;
  font-size: 24px;
} */

.detailimage-container {
  display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.life-image {
  width: 100%;
    margin: 20px 0;
}

/* On large screens (e.g., width ≥ 992px), display images in a row */
@media (min-width: 992px) {
  .life-image {
      width: 48%; /* Adjust the width to fit two images in a row */
  }
}



